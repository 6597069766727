import React, { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DataContext } from "../dataContext";
import TransferDetails from "../components/TransferDetails";
import BottomButton from "../components/BottomButton";

const IncomingDetails = ({ homePath }) => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const { history } = useContext(DataContext);
  const transfer = history.find(({ id }) => id === urlParams.id);

  window.scrollTo(0, 0);

  return (
    <main className="page_with_nav">
      {transfer ? (
        <div>
          <h3 className="status">Payment received</h3>
          <hr style={{ margin: "20px 0" }} />
          {TransferDetails(transfer)}
        </div>
      ) : (
        <div className="center_page">Transfer Not Found</div>
      )}
      {BottomButton(() => navigate(homePath), "Return Home")}
    </main>
  );
};

export default IncomingDetails;
