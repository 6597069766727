import { useEffect, useContext, useState } from "react";
import { DataContext } from "../dataContext";
import useApi from "../api/api";

const FiBranding = () => {
  const api = useApi(false);
  const { loggedIn, setLoggedIn } = useContext(DataContext);

  const defaultBranding = {
    sending_colors: {
      sending_bg_primary: "#ffffff",
      sending_bg_secondary: "#1d74ec",
      sending_bg_tertiary: "#edf3fc",
      sending_text_primary: "#282f38",
      sending_text_secondary: "#758395",
      sending_alert_color: "#ff4620",
      sending_success_color: "#13a61f",
    },
    sms_customization: {
      off_net_first: "I am sending you",
      off_net_second: "from my account at",
      off_net_third: "with the note",
      off_net_fourth: "Click this link to receive your funds:",
      on_net_first: "I am sending you",
      on_net_second: "with the note",
      on_net_third:
        "The funds will be available shortly, and visible via online banking.",
    },
    send_on_network_notification: false,
    event_demo: false,
  };

  const [branding, setBranding] = useState(defaultBranding);
  const root = document.querySelector(":root");

  useEffect(() => {
    const getFiBranding = async () => {
      try {
        const response = await api.get("ui/v2");
        setBranding(response.data);
        // TODO this goes with the question below, setLoggedIn true if successful ui call
        setLoggedIn(true);
      } catch (error) {
        console.error("get branding error", error);
      }
    };
    if (loggedIn) {
      getFiBranding();
    } else {
      // TODO do we want to do something like this? attempt to hit a benign endpoint
      // to check and see if we're authenticated? Could be useful if the user refreshes
      // the page, "loggedIn" will be false but they will still be authenticated
      setTimeout(() => {
        getFiBranding();
      }, 500);
    }
  }, [api, loggedIn, setLoggedIn]);

  const isColor = (str) => {
    if (/^#[0-9A-F]{6}$/i.test(str) === true) {
      return true;
    }
  };

  // check if all branding colors are valid hex codes and set css variables if so
  if (
    branding &&
    isColor(branding?.sending_colors?.sending_bg_primary) &&
    isColor(branding?.sending_colors?.sending_bg_secondary) &&
    isColor(branding?.sending_colors?.sending_bg_tertiary) &&
    isColor(branding?.sending_colors?.sending_text_primary) &&
    isColor(branding?.sending_colors?.sending_text_secondary) &&
    isColor(branding?.sending_colors?.sending_alert_color) &&
    isColor(branding?.sending_colors?.sending_success_color)
  ) {
    root.style.setProperty(
      "--sending_bg_primary",
      branding.sending_colors.sending_bg_primary
    );
    root.style.setProperty(
      "--sending_bg_secondary",
      branding.sending_colors.sending_bg_secondary
    );
    root.style.setProperty(
      "--sending_bg_tertiary",
      branding.sending_colors.sending_bg_tertiary
    );
    root.style.setProperty(
      "--sending_text_primary",
      branding.sending_colors.sending_text_primary
    );
    root.style.setProperty(
      "--sending_text_secondary",
      branding.sending_colors.sending_text_secondary
    );
    root.style.setProperty(
      "--sending_alert_color",
      branding.sending_colors.sending_alert_color
    );
    root.style.setProperty(
      "--sending_success_color",
      branding.sending_colors.sending_success_color
    );
  }

  const dropDownStyle = {
    control: (base) => ({
      ...base,
      border: "none",
      backgroundColor: branding.sending_colors.sending_bg_tertiary,
      height: 54,
      padding: "0 6px",
    }),
    menuList: (base) => ({
      ...base,
      borderRadius: 12,
      backgroundColor: branding.sending_colors.sending_bg_primary,
    }),
    option: (base) => ({
      ...base,
      padding: 15,
      "&:hover": {
        backgroundColor: branding.sending_colors.sending_bg_secondary,
        color: branding.sending_colors.sending_bg_primary,
      },
    }),
  };

  const dropDownTheme = (base) => ({
    ...base,
    colors: {
      primary: branding.sending_colors.sending_bg_tertiary,
    },
    borderRadius: 12,
  });

  if (
    !(
      branding.sms_customization.off_net_first ||
      branding.sms_customization.off_net_second ||
      branding.sms_customization.off_net_third ||
      branding.sms_customization.off_net_fourth ||
      branding.sms_customization.on_net_first ||
      branding.sms_customization.on_net_second ||
      branding.sms_customization.on_net_third
    )
  ) {
    setBranding({
      ...branding,
      sms_customization: defaultBranding.sms_customization,
    });
  }

  return { branding, dropDownStyle, dropDownTheme };
};
export default FiBranding;
