import React from "react";
import { stddatetime, displayTransferRecipient } from "../utils/utils";

const TransferDetails = (transfer) => {
  return (
    <div>
      <h3 style={{ margin: "-15px 0 -20px 0" }}>Transfer details</h3>
      <div className="transfer_detail">Amount</div>
      <div
        style={{ fontSize: "17px", fontWeight: 600 }}
        data-cy="transfer_details_amount"
      >
        ${transfer.amount?.value}
      </div>
      <div className="transfer_detail">Recipient</div>
      <div
        style={{ overflowWrap: "break-word" }}
        data-cy="transfer_details_recipient"
      >
        {displayTransferRecipient(transfer.recipient, false)}
      </div>
      <div className="transfer_detail">Date</div>
      <div data-cy="transfer_details_date">
        {stddatetime(new Date(transfer.timestamp + "Z"))}
      </div>
      <div className="transfer_detail">Comment</div>
      <div data-cy="transfer_details_note">{transfer.note}</div>
      <div className="transfer_detail">Reference ID</div>
      <div>{transfer.id}</div>
    </div>
  );
};

export default TransferDetails;
