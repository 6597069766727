import React from "react";
import {
  displayTransferRecipient,
  displayTransferStatus,
} from "../../utils/utils";
import { ReactComponent as Download } from "../../assets/ico_download.svg";

const IncomingTransfer = ({ incoming, handleLinkClick }) => {
  return (
    <li
      style={{ cursor: "pointer" }}
      onClick={(event) => handleLinkClick(event)}
    >
      <div style={{ display: "flex" }}>
        <div className="history_icon bg_sending_bg_secondary">
          <Download className="fill_sending_bg_secondary stroke_sending_bg_primary" />
        </div>
        <div>
          <div style={{ fontSize: "12px", fontWeight: 550 }}>
            {displayTransferRecipient(incoming.recipient, true)}
          </div>
          <div style={{ fontSize: "10px", marginTop: "3px" }}>
            {displayTransferStatus(incoming)}
          </div>
        </div>
      </div>

      <div className="amount color_sending_success_color">
        +${incoming.amount.value}{" "}
      </div>
    </li>
  );
};

export default IncomingTransfer;
