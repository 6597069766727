import React, { useContext, useEffect } from "react";
import OutgoingTransfer from "./OutgoingTransfer";
import IncomingTransfer from "./IncomingTransfer";
import { yearMonthDate } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../dataContext";
import { ROUTE_CONSTANTS } from "../../constants/routeConstants";

const { INCOMING_DETAILS, OUTGOING_DETAILS } = ROUTE_CONSTANTS;

const History = () => {
  const navigate = useNavigate();
  const { history, getHistory, historyLoading, getNotificationsFeed } =
    useContext(DataContext);

  useEffect(() => {
    getHistory();
    getNotificationsFeed();
  }, [getHistory, getNotificationsFeed]);

  const transfersByDay = () => {
    const days = new Map();

    history.sort((a, b) => {
      if (a.timestamp > b.timestamp) {
        return -1;
      }
      if (a.timestamp < b.timestamp) {
        return 1;
      }
      return 0;
    });

    history.forEach((transfer) => {
      const day = yearMonthDate(new Date(transfer.timestamp + "Z"));
      if (!days.has(day)) {
        days.set(day, []);
      }
      days.get(day).push({ type: "transfer", notification: transfer });
    });

    // sort transfers within each day
    days.forEach((dayTransfers) => {
      dayTransfers.sort((a, b) => {
        if (a.notification.timestamp > b.notification.timestamp) {
          return -1;
        }
        if (a.notification.timestamp < b.notification.timestamp) {
          return 1;
        }
        return 0;
      });
    });

    return days;
  };

  const sortedTransactions = Array.from(transfersByDay());

  return (
    <main className="page_with_nav">
      {sortedTransactions.length ? (
        <ul>
          {sortedTransactions.map(([day, transactions], i) => {
            return (
              <div key={day}>
                <h5
                  style={
                    i === 0
                      ? { textAlign: "center", marginBottom: "10px" }
                      : { textAlign: "center", margin: "13px 0 10px 0" }
                  }
                >
                  {day.slice(5)}
                </h5>
                <ul>
                  {transactions.map((transaction, j) => {
                    const transfer = transaction.notification;
                    switch (transfer.outgoing) {
                      case false: {
                        return (
                          <IncomingTransfer
                            incoming={transfer}
                            key={j}
                            handleLinkClick={() =>
                              navigate(
                                `${INCOMING_DETAILS.replace(":id", transfer.id)}`
                              )
                            }
                          />
                        );
                      }
                      default: {
                        return (
                          <OutgoingTransfer
                            outgoing={transfer}
                            key={j}
                            handleLinkClick={() =>
                              navigate(
                                `${OUTGOING_DETAILS.replace(":id", transfer.id).replace(":action", "view")}`
                              )
                            }
                            isScheduled={!transfer.outgoing}
                          />
                        );
                      }
                    }
                  })}
                  {i !== sortedTransactions.length - 1 && (
                    <hr style={{ margin: "10px 0" }} />
                  )}
                </ul>
              </div>
            );
          })}
        </ul>
      ) : (
        <h3 className="center_page_with_nav">
          {historyLoading ? (
            <>Transfer history is loading.</>
          ) : (
            <>Something went wrong while fetching transfer history.</>
          )}
        </h3>
      )}
    </main>
  );
};

export default History;
