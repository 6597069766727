import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../constants/routeConstants";
import { MOBILE_PROVIDERS } from "../constants/mobileProviders";
import useApi from "../api/api";
import { DataContext } from "../dataContext";
import { SpinnerCircularFixed } from "spinners-react";

export const TokenSignIn = ({ branding }) => {
  const { setLoggedIn, setFundingToken } = useContext(DataContext);

  const navigate = useNavigate();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const api = useApi(false);

  // Set default state
  const [errorMessage, setErrorMessage] = useState(
    "An unknown error occurred. Please try again later."
  );
  const [showError, setShowError] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  const displayError = async (msg) => {
    setShowLoader(false);
    setShowError(true);

    if (msg) {
      setErrorMessage(msg);
    }
  };

  // SSO login process
  useEffect(() => {
    const token = params.token;

    if (!token) {
      console.error("No token found");
      displayError();
      return;
    }

    const handleLogin = async () => {
      try {
        await api.post("session/sso", {
          token: token,
        });
        localStorage.setItem("loginType", "sso");
        delete params.token;

        // If 'next' query param is present - send the user there
        let nextPath = ROUTE_CONSTANTS.MAIN_PAGE;
        if (window.location.pathname.includes("funding")) {
          setFundingToken(token);
          nextPath = ROUTE_CONSTANTS.FUNDING;
        }
        if (params.next) {
          nextPath = params.next;
          delete params.next;
        }

        // If 'mobileProvider' query param is present - set in local storage
        // if not set then set as NONE
        if (
          params.mobileProvider &&
          params.mobileProvider in MOBILE_PROVIDERS
        ) {
          // if params
          localStorage.setItem("mobileProvider", params.mobileProvider);
          delete params.mobileProvider;
        } else {
          localStorage.setItem("mobileProvider", "NONE");
        }

        // Send to next page with any left over querys params
        // A good example of left over params is amount and recipient when going to send page
        navigate({
          pathname: nextPath,
          search: Object.keys(params)
            .map((key) => key + "=" + encodeURIComponent(params[key]))
            .join("&"),
        });
        setLoggedIn(true);
      } catch (error) {
        console.error("login error", error);
        if (error.response?.status === 500) {
          // Error returned from embedded api -
          // this is likely because the user_id for this user is not valid.
          displayError(
            "Looks like your account is not set up. Please check back again later."
          );
          return;
        }
        if (!error.response?.status) {
          displayError(
            "This service is currently down for maintenance. We apologize for the inconvenience."
          );
        }
        displayError();
      }
    };

    // Make request to embedded api to set cookie
    handleLogin();
  }, [params, api, navigate, setFundingToken, setLoggedIn]);

  const Error = () => {
    return (
      <div>
        <h1 id="error">Oh no...</h1>
        <h3 id="error">{errorMessage}</h3>
      </div>
    );
  };

  return (
    <div className="center_page">
      {showError && <Error />}
      {showLoader && (
        <SpinnerCircularFixed
          color={branding?.sending_colors?.sending_bg_secondary}
          secondaryColor={branding?.sending_colors?.sending_bg_primary}
          speed={150}
          style={{ height: "60px", width: "60px" }}
        />
      )}
    </div>
  );
};

export default TokenSignIn;
