import React, { useContext } from "react";
import { DataContext } from "../dataContext";

export const FundingiFrame = ({ branding }) => {
  const { fundingToken } = useContext(DataContext);

  const getIframeUrl = () => {
    const domain = window.location.host;
    if (domain === "localhost:5101") {
      return "http://localhost:3000";
    }
    if (domain === "send.staging.neuralpayments.com") {
      return "https://p2c.app.staging.neuralpayments.com";
    }
    if (domain === "send.neuralpayments.com") {
      return "https://p2c.app.neuralpayments.com";
    }
    return "https://p2c.app.neuralpayments.com";
  };

  const iframeUrl = `${getIframeUrl()}?off_network_bg_primary=${branding.sending_colors.sending_bg_primary.slice(
    1,
    7
  )}&off_network_bg_secondary=${branding.sending_colors.sending_bg_tertiary.slice(
    1,
    7
  )}&off_network_button_color=${branding.sending_colors.sending_bg_secondary.slice(
    1,
    7
  )}&off_network_text_color=${branding.sending_colors.sending_text_primary.slice(
    1,
    7
  )}&app=funding&ui_version=new&token=${fundingToken}`;

  return (
    <iframe
      title={"debit-card-input-iframe"}
      id="cde-iframe"
      style={{
        border: "0",
        height: "100vh",
        width: "100vw",
        margin: "-25px -20px",
      }}
      src={iframeUrl}
      allow=""
    ></iframe>
  );
};

export default FundingiFrame;
