import React from "react";

const BottomButton = (clickEvent, text) => {
  return (
    <div>
      <button style={{ margin: "20px 0" }} onClick={clickEvent}>
        {text}
      </button>
    </div>
  );
};

export default BottomButton;
