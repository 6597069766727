import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../dataContext";
import useApi from "../api/api";

const SetDefaultCard = () => {
  const { defaultAccounts, accounts, getDefaultAccounts, cards, identifiers } =
    useContext(DataContext);
  const api = useApi(false);
  const [chosenAccount, setChosenAccount] = useState({});
  const [chosenCard, setChosenCard] = useState({});

  const identifierValue = window.location.href.split("-account/")[1];

  const chosenIdentifier = identifiers.find(
    ({ value }) => value === identifierValue
  );

  useEffect(() => {
    if (defaultAccounts.length) {
      if (
        defaultAccounts.filter((arr) =>
          arr[1].find(({ value }) => value === identifierValue)
        )[0]
      ) {
        setChosenAccount(
          defaultAccounts.find((arr) =>
            arr[1].find(({ value }) => value === identifierValue)
          )[0]
        );
        setChosenCard(
          defaultAccounts
            .find((arr) =>
              arr[1].find(({ value }) => value === identifierValue)
            )[1]
            .find(({ value }) => value === identifierValue).default_card
        );
      }
    }
  }, [defaultAccounts, identifierValue]);

  const handleAccountChange = async (account) => {
    setChosenAccount(account);
    setChosenCard({});
    if (
      cards.filter((card) => card.account_uuids.includes(account.uuid))
        .length === 1
    ) {
      const url = `/account/${encodeURIComponent(String(account.id))}/default/${encodeURIComponent(String(chosenIdentifier?.type))}/${encodeURIComponent(String(chosenIdentifier?.value))}?default_card=${cards.find((card) => card.account_uuids.includes(account.uuid)).uuid}`;
      try {
        await api.put(url);
        getDefaultAccounts();
      } catch (error) {
        console.error("set default card error", error);
      }
    }
  };

  const handleCardChange = async (card) => {
    setChosenCard(card.uuid);
    const url = `/account/${encodeURIComponent(String(chosenAccount.id))}/default/${encodeURIComponent(String(chosenIdentifier?.type))}/${encodeURIComponent(String(chosenIdentifier?.value))}?default_card=${card.uuid}`;

    try {
      await api.put(url);
      getDefaultAccounts();
    } catch (error) {
      console.error("set default card error", error);
    }
  };

  const chosenAccountCards = cards.filter((card) =>
    card.account_uuids.includes(chosenAccount.uuid)
  );

  return (
    <div className="page_with_nav">
      <div>
        <div
          className="color_sending_text_secondary"
          style={{
            margin: "10px 0 30px",
            wordBreak: "break-word",
          }}
        >
          Set defaults for {chosenIdentifier?.value}
        </div>
        <div style={{ fontSize: "16px", marginBottom: "20px" }}>
          Select Account
        </div>
        {accounts.map((account, i) => {
          return (
            <div key={i}>
              <div style={{ display: "flex", justifyContent: "start" }}>
                <input
                  id={`account-${account.name}`}
                  type="radio"
                  checked={account.id === chosenAccount.id}
                  onChange={() => handleAccountChange(account)}
                ></input>
                <label
                  htmlFor={`account-${account.name}`}
                  style={{
                    fontSize: "14px",
                    marginTop: "0",
                    fontWeight: 400,
                  }}
                >
                  {account.name}
                </label>
              </div>
              {i !== accounts.length - 1 && (
                <hr style={{ margin: "10px 0 20px" }} />
              )}
            </div>
          );
        })}
        <div
          style={{
            fontSize: "16px",
            margin: "30px 0 20px",
          }}
        >
          Select Card
        </div>
        {chosenAccountCards.map((card, i) => {
          return (
            <div key={i}>
              <div style={{ display: "flex", justifyContent: "start" }}>
                <input
                  id={`card-${card.uuid}`}
                  type="radio"
                  checked={chosenCard === card.uuid}
                  onChange={() => handleCardChange(card)}
                ></input>
                <label
                  htmlFor={`card-${card.uuid}`}
                  style={{
                    fontSize: "14px",
                    marginTop: "0",
                    fontWeight: 400,
                  }}
                >
                  Card Ending {card.last_four}
                </label>
              </div>
              {i !== chosenAccountCards.length - 1 && (
                <hr style={{ margin: "10px 0 20px" }} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SetDefaultCard;
