import { postUniversalMessage } from "@jack-henry/banno-plugin-framework-bridge";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const stddatetime = (date) => {
  const month = months[date.getMonth()];
  const day = date.getDate();
  let hours = date.getHours();
  let am_pm = "AM";
  const minutes = "0" + date.getMinutes();
  if (hours === 0) {
    hours = 12;
  } else if (hours === 12) {
    am_pm = "PM";
  } else if (hours > 12) {
    am_pm = "PM";
    hours -= 12;
  }
  return (
    month + " " + day + " " + hours + ":" + minutes.slice(-2) + " " + am_pm
  );
};

export const yearMonthDate = (date) => {
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();
  return year + " " + month + " " + day;
};

export const monthDateYear = (date) => {
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();
  return month + " " + day + ", " + year;
};

export const displayTransferRecipient = (recipient, shouldShorten) => {
  switch (recipient.type) {
    case "email":
    case "pushtocard": {
      if (recipient.value.length > 25 && shouldShorten && isMobile()) {
        return `${recipient.value.slice(0, 25)}...`;
      } else {
        return recipient.value;
      }
    }
    case "phone": {
      return `(${recipient.value.slice(-10, -7)}) ${recipient.value.slice(-7, -4)}-${recipient.value.slice(-4)}`;
    }
    default:
      return recipient.value;
  }
};

export const displayTransferStatus = (transfer) => {
  switch (transfer.status) {
    case "SUCCEEDED": {
      switch (transfer.outgoing) {
        case false: {
          return "Received";
        }
        default:
          return "Sent";
      }
    }
    default:
      return transfer.status.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
  }
};

export const getOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase(),
    macosPlatforms = /(mac)/i,
    windowsPlatforms = /(win32|win64|windows|wince)/i,
    iosPlatforms = /(iphone|ipad|ipod)/i;

  let os = null;

  if (macosPlatforms.test(userAgent)) {
    os = "macos";
  } else if (iosPlatforms.test(userAgent)) {
    os = "ios";
  } else if (windowsPlatforms.test(userAgent)) {
    os = "windows";
  } else if (/android/.test(userAgent)) {
    os = "android";
  } else if (!os && /linux/.test(userAgent)) {
    os = "linux";
  } else {
    os = "unknown";
  }

  return os;
};

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

export const deviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

/**
 * Uses the banno plugin bridge click-link app event to open link in an external browser.
 * This is useful when you need to send a sms message. If the postUniversalMessage is unsuccessful
 * It will fallback to setting the sms as a href. This is useful if you in a banno flow
 * but in a new tab and cant send messages to a parent frame.
 * @param {string} href - The link you want to send the user to
 * @param {boolean} external - If you want to open this in a new tab/out of the mobile app
 * @returns Opens link in banno environment
 */
export const hrefRouter = (href, external) => {
  const messageSent = postUniversalMessage({
    type: "click-link",
    data: { href: href, external: external },
  });
  // Message was not sent. Fallback to setting window href
  if (!messageSent) {
    window.location.href = href;
  }
};
