import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../constants/routeConstants";
import { DataContext } from "../dataContext";
import { ReactComponent as ChevronRight } from "../assets/ico_chevron_right.svg";

const { SET_DEFAULT_ACCOUNT } = ROUTE_CONSTANTS;

const Settings = () => {
  const { defaultAccounts, defaultAccountsLoading, cards, identifiers } =
    useContext(DataContext);
  const navigate = useNavigate();

  const identifierTypes = [
    ...new Set(
      identifiers
        .sort((a, b) => a.type.localeCompare(b.type))
        .map(({ type }) => type)
    ),
  ];

  return (
    <main className="page_with_nav">
      {identifiers.length && defaultAccounts.length ? (
        <div>
          {identifierTypes.map((identifierType) => {
            return (
              <div
                key={identifierType}
                style={
                  identifierType === identifierTypes[0]
                    ? { marginTop: "10px" }
                    : { marginTop: "30px" }
                }
              >
                <div className="color_sending_text_secondary">
                  Default card for {identifierType}
                </div>
                {identifiers
                  .filter(({ type }) => type === identifierType)
                  .map((identifier) => {
                    let defaultCardForIdentifier = undefined;
                    defaultAccounts.find((accounts) => {
                      defaultCardForIdentifier = accounts[1].find(
                        ({ value }) => value === identifier.value
                      );
                      return defaultCardForIdentifier;
                    });
                    return (
                      <div
                        key={identifier.value}
                        className="status"
                        style={{
                          marginTop: "10px",
                          alignItems: "center",
                        }}
                        onClick={() =>
                          navigate(
                            SET_DEFAULT_ACCOUNT.replace(":id", identifier.value)
                          )
                        }
                      >
                        <div
                          style={{
                            wordBreak: "break-word",
                            fontSize: "12px",
                          }}
                        >
                          {identifier.value}{" "}
                          <div
                            style={{
                              fontSize: "10px",
                              fontWeight: 400,
                              marginTop: "10px",
                            }}
                          >
                            {defaultCardForIdentifier ? (
                              <>
                                {cards.find(
                                  (card) =>
                                    defaultCardForIdentifier.default_card ===
                                    card.uuid
                                )?.last_four ? (
                                  <>
                                    {/* 1st choice is show the card last_four */}
                                    Card Ending{" "}
                                    {
                                      cards.find(
                                        (card) =>
                                          defaultCardForIdentifier.default_card ===
                                          card.uuid
                                      )?.last_four
                                    }
                                  </>
                                ) : (
                                  <>
                                    {/* 2nd choice is show the account name */}
                                    {
                                      defaultAccounts.find((arr) =>
                                        arr[1].find(
                                          ({ value }) =>
                                            value === identifier.value
                                        )
                                      )[0].name
                                    }
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {/* Final choice is show this if there's no account or card */}
                                Another account and/or institution
                              </>
                            )}
                          </div>
                        </div>
                        <ChevronRight className="stroke_sending_text_primary" />
                      </div>
                    );
                  })}
              </div>
            );
          })}
        </div>
      ) : (
        <h3 className="center_page_with_nav">
          {defaultAccountsLoading ? (
            <>Account and settings data is loading.</>
          ) : (
            <>Something went wrong while fetching account and settings data.</>
          )}
        </h3>
      )}
    </main>
  );
};

export default Settings;
