import React from "react";
import { ReactComponent as Card } from "../assets/ico_card.svg";

const DoubleIcon = (smallIcon) => {
  const renderDoubleIcon = () => {
    return (
      <>
        <div
          className="center_div bg_sending_text_secondary"
          style={{
            height: "80px",
            width: "80px",
            borderRadius: "40px",
          }}
        >
          <div
            className="center_div bg_sending_bg_primary"
            style={{
              height: "46px",
              width: "50px",
              borderRadius: "12px",
            }}
          >
            <Card
              style={{
                height: "60px",
                width: "auto",
                margin: "-7px -5px -5px -5px",
              }}
              className="stroke_sending_text_secondary"
            />
          </div>
        </div>
        {smallIcon()}
      </>
    );
  };

  return { renderDoubleIcon };
};

export default DoubleIcon;
