import React, { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DataContext } from "../dataContext";
import { ReactComponent as Fail } from "../assets/ico_fail.svg";
import DoubleIcon from "../components/DoubleIcon";
import BottomButton from "../components/BottomButton";

const TransferFailed = ({ homePath }) => {
  const { history } = useContext(DataContext);
  const navigate = useNavigate();

  const urlParams = useParams();

  const transfer = history.find(({ id }) => id === urlParams.id);

  const smallFailIcon = () => {
    return (
      <Fail
        style={{
          height: "auto",
          width: "40px",
          margin: "-35px 0 0 60px",
          zIndex: 1,
          borderRadius: "20px",
        }}
        className="fill_sending_alert_color stroke_sending_bg_primary bg_sending_bg_primary"
      />
    );
  };

  const { renderDoubleIcon } = DoubleIcon(smallFailIcon);

  return (
    <main className="page_without_nav ">
      {transfer ? (
        <>
          <div
            className="center_page"
            style={{
              height: "65%",
              flexDirection: "column",
            }}
          >
            <h1>Error</h1>
            <div
              data-cy="transfer_processed_message"
              style={{ margin: "-15px 0 25px 0", fontSize: "16px" }}
            >
              Transfer failed
            </div>
            {renderDoubleIcon()}
          </div>
        </>
      ) : (
        <div className="center_page">Transfer Not Found</div>
      )}
      {BottomButton(() => navigate(homePath), "Return Home")}
    </main>
  );
};

export default TransferFailed;
