import { useMemo } from "react";
import axios from "axios";

function useApi(isScheduled) {
  let baseUrl = process.env.REACT_APP_API_URL;

  if (isScheduled) {
    baseUrl = baseUrl.replace("/embedded", "/scheduler");
  }

  const httpRequest = useMemo(
    () =>
      axios.create({
        baseURL: baseUrl,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    [baseUrl]
  );

  let api = httpRequest;

  return api;
}

export default useApi;
