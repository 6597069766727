import React from "react";
import {
  displayTransferRecipient,
  displayTransferStatus,
} from "../../utils/utils";
import { ReactComponent as Clock } from "../../assets/ico_clock.svg";
import { ReactComponent as Clock2 } from "../../assets/ico_clock_2.svg";
import { ReactComponent as Upload } from "../../assets/ico_upload.svg";
import { ReactComponent as Fail } from "../../assets/ico_fail.svg";
import { ReactComponent as Ellipsis } from "../../assets/ico_ellipsis.svg";
import { monthDateYear } from "../../utils/utils";

const OutgoingTransfer = ({ outgoing, handleLinkClick, isScheduled }) => {
  const smallIconStyle = {
    margin: "18px 2px -4px -24px",
    transform: "scale(.8)",
    borderRadius: "10px",
  };

  const smallIcon = () => {
    switch (outgoing.status) {
      case "SCHEDULED":
        return (
          <Clock2
            style={smallIconStyle}
            className="fill_sending_text_primary bg_sending_bg_primary"
          />
        );
      case "PENDING":
        return (
          <Ellipsis
            style={smallIconStyle}
            className="fill_sending_text_primary bg_sending_bg_primary"
          />
        );
      case "FAILED":
        return (
          <Fail
            style={smallIconStyle}
            className="fill_sending_alert_color stroke_sending_bg_primary bg_sending_bg_primary"
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <li
      style={{ cursor: "pointer" }}
      onClick={(event) => handleLinkClick(event)}
    >
      {outgoing.recipient && (
        <>
          <div style={{ display: "flex" }}>
            <div className="history_icon bg_sending_bg_tertiary">
              <div className="center_div" style={{ width: "36px" }}>
                {isScheduled && outgoing.next_occurrence ? (
                  <Clock
                    className="fill_sending_bg_secondary"
                    style={{ height: "17px" }}
                  />
                ) : (
                  <Upload className="fill_sending_bg_tertiary stroke_sending_bg_secondary" />
                )}
              </div>
            </div>
            {smallIcon()}
            <div>
              <div
                data-cy="recipient_value"
                style={{ fontSize: "12px", fontWeight: 550 }}
              >
                {displayTransferRecipient(outgoing.recipient, true)}
              </div>
              <div style={{ fontSize: "10px", marginTop: "3px" }}>
                {isScheduled ? (
                  <>
                    {outgoing.comment}
                    <div style={{ lineHeight: "15px" }}>
                      {outgoing.repeat_frequency.charAt(0).toUpperCase() +
                        outgoing.repeat_frequency.substr(1).toLowerCase()}
                      <span style={{ margin: "0 3px" }}> | </span>Next:{" "}
                      {monthDateYear(
                        new Date(
                          `${outgoing.next_occurrence?.split("T")[0]}T00:00:00`
                        )
                      )}
                    </div>
                  </>
                ) : (
                  displayTransferStatus(outgoing)
                )}
              </div>
            </div>
          </div>
          <div
            className={
              outgoing.status === "FAILED"
                ? "amount color_sending_text_secondary"
                : "amount"
            }
          >
            -${parseFloat(outgoing.amount.value).toFixed(2)}
          </div>
        </>
      )}
    </li>
  );
};

export default OutgoingTransfer;
