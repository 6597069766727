import React, { useEffect, useContext } from "react";
import { DataContext } from "../dataContext";
import useApi from "../api/api";

const SignOut = () => {
  const api = useApi(false);
  const { setLoggedIn } = useContext(DataContext);

  useEffect(() => {
    const signOut = async () => {
      try {
        await api.delete("session");
        setLoggedIn(false);
      } catch (error) {
        console.error("logout error", error);
      }
    };
    signOut();
  });

  return <h3 className="center_page">You have been logged out</h3>;
};

export default SignOut;
