import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../constants/routeConstants";
import { DataContext } from "../dataContext";
import TransferDetails from "../components/TransferDetails";
import { ReactComponent as Info } from "../assets/ico_info.svg";
import BottomButton from "../components/BottomButton";
import { SpinnerCircularFixed } from "spinners-react";

const { TRANSFER_SUCCESSFUL, TRANSFER_FAILED } = ROUTE_CONSTANTS;

const TransferProcessing = ({ branding, homePath }) => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const [transferStatus, setTransferStatus] = useState("PENDING");
  const { history, getHistory, notificationFeed, getNotificationsFeed } =
    useContext(DataContext);

  const transfer = history.find(({ id }) => id === urlParams.id);
  const notification = notificationFeed.find(
    ({ transfer_id }) => transfer_id === urlParams.id
    // notification/feed doesn't work locally
    // ({ transfer_id }) => transfer_id === "c35bc840-1865-5bf5-916d-b85ce20589c1" // off network WITH shortlink
    // ({ transfer_id }) => transfer_id === "c35bc840-1865-5bf5-916d-b85ce20589c2" // off network WITHOUT shortlink
    // ({ transfer_id }) => transfer_id === "17bfb8a4-2fa3-5615-b4b4-e3cc6151c65d" // on network succeeded
    // ({ transfer_id }) => transfer_id === "9754335f-7db7-5f10-9db0-79fd5b6fcb02" // on network failed
  );

  useEffect(() => {
    const pollForResult = () => {
      getHistory();
      getNotificationsFeed();
      if (transfer) {
        setTransferStatus(transfer.status);
      }
      if (
        notification &&
        (notification?.status === "succeeded" || notification?.shortlink)
      ) {
        navigate(`${TRANSFER_SUCCESSFUL.replace(":id", urlParams.id)}`);
      }
      if (notification && notification?.status === "failed") {
        navigate(`${TRANSFER_FAILED.replace(":id", urlParams.id)}`);
      }
    };
    if (transferStatus === "PENDING") {
      const interval = setInterval(() => {
        pollForResult();
      }, 3000);
      setTimeout(() => {
        clearInterval(interval);
        setTransferStatus("TIMEDOUT");
      }, 20000);
      return () => clearInterval(interval);
    }
  }, [
    getHistory,
    getNotificationsFeed,
    transfer,
    notification,
    transferStatus,
    urlParams,
    navigate,
  ]);

  return (
    <>
      {transferStatus !== "TIMEDOUT" ? (
        <div className="center_page">
          <SpinnerCircularFixed
            color={branding.sending_colors.sending_bg_secondary}
            secondaryColor={branding.sending_colors.sending_bg_primary}
            speed={150}
            style={{ height: "60px", width: "60px" }}
          />
        </div>
      ) : (
        <main className="page_without_nav">
          {transfer?.status ? (
            <div>
              <h3 data-cy="transfer_processed_message" className="status">
                Your transfer is still processing. Please check the status in
                the "History" tab later.
              </h3>
              {!transfer.on_network && (
                <div className="transfer_action_info">
                  <div style={{ marginRight: "10px" }}>
                    <Info
                      className="fill_sending_bg_primary stroke_sending_bg_secondary"
                    />
                  </div>
                  {transfer.recipient?.type === "phone" ? (
                    <>
                      You must return later to send a text message containing
                      the link required by the recipient to receive the funds.
                    </>
                  ) : (
                    <>
                      An email will be sent to the recipient once the transfer
                      has processed.
                    </>
                  )}
                </div>
              )}
              <hr style={{ margin: "20px 0" }} />
              {TransferDetails(transfer)}
            </div>
          ) : (
            <div className="center_page">Transfer Not Found</div>
          )}
          {BottomButton(() => navigate(homePath), "Return Home")}
        </main>
      )}
    </>
  );
};

export default TransferProcessing;
