import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { datadogRum } from "@datadog/browser-rum";

datadogRum.init({
  applicationId: "6e6b05d2-f406-4b45-bc53-03107bb74bb0",
  clientToken: "pubb92fd9919d0d987ef1e28c6af2b4dd34",
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "us5.datadoghq.com",
  service: "sending-app",
  env: process.env.ENV_NAME,
  // Specify a version number to identify the deployed version of your application in Datadog
  version: "1.0.0",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  startSessionReplayRecordingManually: true,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
